import React,{useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import SelectComponent from "&styled/form/select";
import { TextInput } from "&styled/textField/textField.component";
import { SubmitButton} from "&styled/button/button.component";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "34px",
  p: 4,
};

const COUNTRIES = [
  {label: "Egypt", value: "EG"},

]
const generateRandomNumber=() => {
    const timestamp = Date.now().toString();
    const randomString = timestamp.substring(timestamp.length - 8);
    const randomNumber = parseInt(randomString, 10);
    return randomNumber;
  }

export default function CreateNewModal({
  open,
  handleClose,
  handleCreate,
  getServices,
}) {
    const [services, setServices] = useState([])

    useEffect(() => {
      
        (async()=>{
          const {payload} = await getServices()
          if(payload.length){
            const servicesArr: any = [];
            payload.forEach((s)=>{
              servicesArr.push({label: s.serviceName, value: s.serviceId})
            })
            setServices(servicesArr)
          }
        })()
        
      }, [getServices]);
    
      const validation = Yup.object().shape({
        serviceId: Yup.string()
          .required("Please provide valid service"),
        amount: Yup.string()
          .matches(/^[0-9]+$/, 'Amount must contain only digits from 0 to 9')
          .required("Please provide valid amount"),
          fullName: Yup.string().trim()
          .required("Please provide valid Full Name"),
          nationalId: Yup.string().trim()
          .matches(/^[0-9]+$/, 'National ID must contain only digits from 0 to 9')
          .required("Please provide valid Payout type"),
          msisdn: Yup.string().trim()
          .matches(/^20(10|11|12|15)[0-9]{8}$/, 'Please provide a valid Egyptian mobile number with country code')
          .required("Please provide valid MSISDN"),
          beneficiaryEmail: Yup.string().trim()
          .required("Please provide valid email address"),
          countryCode: Yup.string().trim()
          .required("Please provide valid Country"),
      });

      const initialValues = {
        "serviceId":"",
        "amount":"",
        "fullName":"",
        "nationalId":"",
        "msisdn":"",
        "countryCode":"EG",
        "beneficiaryEmail":""
      }

      const handleSubmit = async(vals) => {
        vals.merchantOrderId = generateRandomNumber();
     
        try{
            await handleCreate(vals)
            handleClose()
        }catch(e){
            console.log(e)
        }
      }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="create-modal"
        aria-describedby="create-modal"
      >
        <Box sx={style}>
        <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={(values: typeof initialValues) => {
                handleSubmit(values);
              }}
              validationSchema={validation}
            >
              {(formik: FormikProps<typeof initialValues>) => (
                <Grid container spacing={2}>
                  <Grid item  sm={12}>
                  <SelectComponent
                    onSelect={formik.handleChange("serviceId")}
                    value={formik.values.serviceId}
                    size="small"
                    placeHolder="Select Service"
                    menuItems={services || []}
                    hasError={!!formik.errors.serviceId}
                    errorMessage={formik.errors.serviceId as string}
                  />
                  </Grid>

                  <Grid item sm={12}>
                  <TextInput
                      placeHolder={"Amount"}
                      value={formik.values.amount}
                      handleTextChange={formik.handleChange("amount")}
                      hasError={!!formik.errors.amount}
                      errorMessage={formik.errors.amount as string}
                    />
                  </Grid>

                  <Grid item sm={12}>
                  <TextInput
                      placeHolder={"Full Name"}
                      value={formik.values.fullName}
                      handleTextChange={formik.handleChange("fullName")}
                      hasError={!!formik.errors.fullName}
                      errorMessage={formik.errors.fullName as string}
                    />
                  </Grid>
                  <Grid item sm={12}>
                  <TextInput
                      placeHolder={"National ID"}
                      value={formik.values.nationalId}
                      handleTextChange={formik.handleChange("nationalId")}
                      hasError={!!formik.errors.nationalId}
                      errorMessage={formik.errors.nationalId as string}
                    />
                  </Grid>

                  <Grid item  sm={12}>
                  <SelectComponent
                    onSelect={formik.handleChange("countryCode")}
                    value={formik.values.countryCode}
                    size="small"
                    placeHolder="Select Country"
                    menuItems={COUNTRIES}
                    hasError={!!formik.errors.countryCode}
                    errorMessage={formik.errors.countryCode as string}
                  />
                  </Grid>

                  <Grid item  sm={12}>
                  <TextInput
                      placeHolder={"Mobile Number (e.g 201112345678)"}
                      value={formik.values.msisdn}
                      handleTextChange={formik.handleChange("msisdn")}
                      hasError={!!formik.errors.msisdn}
                      errorMessage={formik.errors.msisdn as string}
                    />
                  </Grid>

                  <Grid item sm={12}>
                  <TextInput
                      placeHolder={"Email Address"}
                      value={formik.values.beneficiaryEmail}
                      handleTextChange={formik.handleChange("beneficiaryEmail")}
                      hasError={!!formik.errors.beneficiaryEmail}
                      errorMessage={formik.errors.beneficiaryEmail as string}
                    />
                  </Grid>


                  <Grid item lg={12} marginTop={"3rem"}>
                    <SubmitButton
                      title="Save"
                      handlePress={() => {
                        formik.handleSubmit();
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Formik>
        </Box>
      </Modal>
    </div>
  );
}
