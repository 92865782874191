import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { history } from "&store/store";

import { RootState } from "&store/store";
import { LoginComponent } from "&features/login/login.component";

import { languageActions } from "./redux/language";
import { LandingComponent } from "&features/landing/landing.component";
import { MenaRedirectComponent } from "&features/egyptCheckout/welcomePage/menaRedirect.component";
import { EgyptCheckoutComponent } from "&features/egyptCheckout/egyptCheckout.component";
import { PaytabWelcomeComponent } from "&features/egyptCheckout/payTabWelcome";
import { CheckoutLinkComponent } from "&features/packages/checkoutLink";
import { PaymobRedirectComponent } from "&features/egyptCheckout/payMobWelcome/paymobRedirect.component";

type ReduxProps = ConnectedProps<typeof connector>;

const AuthRouter = (props: ReduxProps) => {
  return (
    <>
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={LandingComponent} />
          <Route exact path="/login" component={LoginComponent} />
          <Route
            exact
            path="/checkout/mena/:serviceId/:orderId"
            component={MenaRedirectComponent}
          />
          <Route
            exact
            path="/checkout/mena/paytab/redirect/:serviceId/:orderId"
            component={PaytabWelcomeComponent}
          />
           <Route exact path="/hosted-checkout/mena/eg" component={PaymobRedirectComponent} />
           <Route
            exact
            path="/checkout/via-link/:id"
            component={CheckoutLinkComponent}
          />
          <Route exact path="/hosted-checkout/eg/:sessionId" component={EgyptCheckoutComponent} />
         <Route path="/404" render={() => <div>page not found</div>} />
          <Redirect to="/login" />
        </Switch>
      </Router>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
  language: state.language.language,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  setLanguage: languageActions.setLanguage,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const AuthRouteredux = connector(AuthRouter);

export { AuthRouteredux as AuthRouter };
