import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import { Reports } from "./reports.type";

const url = process.env.REACT_APP_REPORT_URL;
const initialState: Reports = {
  type: "",
  billingType: "",
  data: []
};

const getTransactionReports = createAsyncThunk(
  "reports/getTransactionReportsStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/transaction-report`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(url!.concat(pathname), body, { headers })
      );
      console.log("Success response from transaction report :", response.data);
      dispatch(reportsActions.setData(response.data));
      return response.data;
    } catch (e) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);
const searchTransactionReports = createAsyncThunk(
  "reports/searchTransactionReportsStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/transaction-report`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(url!.concat(pathname), body, { headers })
      );
      console.log("Success response from transaction report :", response.data);
      dispatch(reportsActions.setData(response.data));
      return response.data;
    } catch (e) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);


const searchSettlementReports = createAsyncThunk(
  "reports/searchSettlementReportsStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/settlement-report`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(url!.concat(pathname), body, { headers })
      );
      console.log("Success response from transaction report :", response.data);
      dispatch(reportsActions.setData(response.data));
      return response.data;
    } catch (e) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);
const searchBillingReports = createAsyncThunk(
  "reports/searchBillingReportsStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/billing-report`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(url!.concat(pathname), body, { headers })
      );
      console.log("Success response from Billing report :", response.data);
      dispatch(reportsActions.setData(response.data));
      return response.data;
    } catch (e) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const updateBillingStatus = createAsyncThunk(
  "reports/updateBillingStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/billing-status-update`;
    const token = getState().login.token;
    console.log("State is :", getState().reports)
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.put(url!.concat(pathname), body, { headers })
      );
      console.log("Updating status :", body)
      dispatch(reportsActions.updateStatus({id: body.id, status: body.status}));
      return response.data;
    } catch (e) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const getBillingDetail = createAsyncThunk(
  "reports/searchBillingReportsStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/billing-report/detail`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(url!.concat(pathname), body, { headers })
      );
      console.log("Success response from Billing report :", response.data);
      dispatch(reportsActions.setData(response.data));
      return response.data;
    } catch (e) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const getSettlementSummary = createAsyncThunk(
  "reports/searchSettlementReportsStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/settlement-report/${arg}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(url!.concat(pathname), { headers })
      );
      console.log("Success response from transaction report :", response.data);
      dispatch(reportsActions.setData(response.data));
      return response.data;
    } catch (e) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);


const reportsSlice = createSlice({

  name: "reports",
  initialState: initialState,

  reducers: {
    setReports: (state, action) => {
      return { ...state, ...action.payload };
    },
    setType: (state, action) => {
      state.type = action.payload;
      state.data = []
    },
    setBillingType: (state, action) => {
      state.billingType = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    updateStatus: (state, action)=>{
      state.data = state.data.map((data)=> data._id === action.payload.id ? {...data, status: action.payload.status} : data)
    },
    reset: () => initialState,
  } 
});

export const reportsReducer = reportsSlice.reducer;

export const reportsActions = {
  ...reportsSlice.actions,
  searchTransactionReports,
  getTransactionReports,
  searchSettlementReports,
  searchBillingReports,
  getBillingDetail,
  getSettlementSummary,
  updateBillingStatus
};
