
export function cleanupMobile(mobile){
    let mobileNumber = mobile;
    if(mobile.startsWith("92")){
        mobileNumber = mobile.substring(mobile.indexOf("92")+1);
    }
    if(mobile.startsWith("+92")){
         mobileNumber = mobile.substring(mobile.indexOf("+92")+1);
    }
    if(mobile.startsWith("0")){
        mobileNumber = mobile.substring(mobile.indexOf("0")+1);
    }
    if(mobile.startsWith("20")){
        mobileNumber = mobile.substring(mobile.indexOf("20")+2);
    }
    if(mobile.startsWith("+20")){
        mobileNumber = mobile.substring(mobile.indexOf("+20")+2);
    }
    return mobileNumber;
}
