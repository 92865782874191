import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { RootState } from "&store/store";
import { fawryCheckoutActions } from "./egyptCheckout.slice";
import {servicesActions} from '&features/services/services.slice';
import ErrorScreen from "./error";
import { FawryCheckoutComponent } from "./fawryCheckout";
import { PaytabCheckoutComponent } from "./payTabCheckout";
import { PaymobCheckoutComponent } from "./paymobCheckout";

type ReduxProps = ConnectedProps<typeof connector>;

const EgyptCheckoutComponent = (props: ReduxProps) => {
  const { sessionId, getSessionInfo,  getService} = props;
  const [error, setError] = useState("");
  const [serviceProvider, setServiceProvider] = useState("")
  
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    (async () => {
      try {
        const { payload } = await getSessionInfo(sessionId);
        if (payload.errors) {
          setError(payload.errors[0].message);
        }
        if(payload){
          const {payload: service} = await getService(payload.serviceId)
          setServiceProvider(service.serviceProvider)
        }
       
      } catch (e) {
        console.log("error :", e);
      }
    })();
  }, [sessionId, getSessionInfo, getService]);

 
  return (
    <>
     {error && <ErrorScreen error={error} matches={matches} />}
    {serviceProvider === "Fawry" && <FawryCheckoutComponent sessionId = {sessionId}/>}
     {serviceProvider === "Paytab" && <PaytabCheckoutComponent sessionId={sessionId}/>}
     {serviceProvider === "Paymob" && <PaymobCheckoutComponent sessionId={sessionId}/>}
  </>
  )
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState, ownProps) => ({
  sessionId: ownProps.match.params.sessionId,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getSessionInfo: fawryCheckoutActions.getSessionInfo,
  checkout3D: fawryCheckoutActions.checkoutVia3D,
  checkoutViaQr: fawryCheckoutActions.checkoutViaQr,
  checkoutViaR2p: fawryCheckoutActions.checkoutViaR2p,
  checkoutViaFawryRef: fawryCheckoutActions.checkoutViaFawryRef,
  checkStatus: fawryCheckoutActions.checkStatus,
  updateStatus: fawryCheckoutActions.updateStatus,
  getService: servicesActions.getServiceBySid,
  
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const EgyptCheckoutComponentRedux = connector(EgyptCheckoutComponent);

export { EgyptCheckoutComponentRedux as EgyptCheckoutComponent };
