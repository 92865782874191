import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";
import { WALEE_BASE_URL } from "&config/url";
import { Login, LoginBody } from "./login.type";

/**
 * Initial state object
 */
const initialState: Login = {
  username: "",
  mobile: "",
  isLoggedIn: false,
  token: "",
};

const loginCall = createAsyncThunk(
  "login/loginApiCallStatus",
  async ({ userName, password, rememberMe }: any, { rejectWithValue }) => {
    const pathname = `/auth/login`;

    /** Construct body */
    const body: LoginBody = {
      userName: userName.toLowerCase().trim(),
      password,
    };

    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(WALEE_BASE_URL.concat(pathname), body)
      );

      return response.data;
    } catch (e) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

/**
 * Feature slice Object
 * Automatically generates actions as per reducers
 */
const loginSlice = createSlice({
  /**
   * Unique feature name
   */
  name: "login",

  /**
   * Initial state object
   */
  initialState: initialState,

  reducers: {
    setLogin: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
    // Add here reducers
    // ...
  },
  /**
   * Extra reducers are for handling action types.
   * Here thunk actions are handled
   */
  extraReducers: (builder) => {
    // TODO remove extraReducers if there are no thunks
    builder
      .addCase(loginCall.pending, (state, action) => {
        // Write pending logic here
        console.log(action);
      })
      .addCase(loginCall.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.token = action.payload;
      })
      .addCase(loginCall.rejected, (state, { payload }: any) => {
        const error = payload?.errors[0].message || "Something went wrong";
        toast.error(error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        state.isLoggedIn = false;
      });
  },
});

/**
 * Reducers are exported so they could be added to store
 */
export const loginReducer = loginSlice.reducer;

/**
 * Actions hold the same names as reducers.
 * Actions can be dispached using 'useDispacth' hook,
 * or by 'mapDispatchToProps' in the redux 'connect' function
 */
export const loginActions = { ...loginSlice.actions, loginCall };
