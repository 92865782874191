import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Swal from "sweetalert2";
import { history } from "&store/store";
import { trackPromise } from "react-promise-tracker";
import { Settlement } from "./settlement.type";
import { WALEE_BASE_URL } from "&config/url";

/**
 * Initial state object
 */
const initialState: Settlement = {
  data: [],
};

const getSettlements = createAsyncThunk(
  "settlement/getSettlementsStatus",
  async (arg: void, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/settlements/get`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(WALEE_BASE_URL.concat(pathname), { headers })
      );
      console.log("Success response from transaction report :", response.data);
      dispatch(settlementActions.setData(response.data));
      return response.data;
    } catch (e) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);
const getSettlement = createAsyncThunk(
  "settlement/getSettlementsStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/settlements/get/${arg}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(WALEE_BASE_URL.concat(pathname), { headers })
      );
      console.log("Success response from transaction report :", response.data);
      dispatch(settlementActions.setData(response.data));
      return response.data;
    } catch (e) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const addSettlement = createAsyncThunk(
  "settlement/addSettlementsStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/settlements/add`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(WALEE_BASE_URL.concat(pathname), arg, { headers })
      );
      console.log("Success response from adding settlement :", response.data);
      dispatch(settlementActions.setData(response.data));
      return response.data;
    } catch (e) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const settlementSlice = createSlice({
  name: "settlement",

  initialState: initialState,

  reducers: {
    setSettlement: (state, action) => {
      return { ...state, ...action.payload };
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    reset: () => initialState,
    // Add here reducers
    // ...
  },
  /**
   * Extra reducers are for handling action types.
   * Here thunk actions are handled
   */
  extraReducers: (builder) => {
    // TODO remove extraReducers if there are no thunks
    builder.addCase(getSettlements.pending, (state, action) => {
      // Write pending logic here
    });
    builder.addCase(getSettlements.fulfilled, (state, action) => {
      // Write success logic here
    });
    builder.addCase(getSettlements.rejected, (state, { payload }: any) => {
      const { status, message } = payload;
      console.log("status of token is :", status);
      if (status === "Token-Expired" || status === "Invalid-Token") {
        Swal.fire({
          title: "Info",
          icon: "info",
          titleText: message,
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((response) => {
          if (response.value) {
            history.push("/logout");
          }
        });
      }
    });
  },
});

/**
 * Reducers are exported so they could be added to store
 */
export const settlementReducer = settlementSlice.reducer;

/**
 * Actions hold the same names as reducers.
 * Actions can be dispached using 'useDispacth' hook,
 * or by 'mapDispatchToProps' in the redux 'connect' function
 */
export const settlementActions = {
  ...settlementSlice.actions,
  getSettlements,
  getSettlement,
  addSettlement
};
