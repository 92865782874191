import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";

import { RootState } from "&store/store";
import { fawryCheckoutActions } from "../egyptCheckout.slice";
import {servicesActions} from '&features/services/services.slice';

import Loader from "&styled/loader";

type ReduxProps = ConnectedProps<typeof connector> & {
    sessionId: string;
  };

const PaytabCheckoutComponent = (props: ReduxProps & React.PropsWithChildren<{}>) => {
  const { sessionId, checkoutViaPaytab} = props;

  useEffect(() => {
    (async () => {
    
      try {
        const {payload} = await checkoutViaPaytab({sessionId});
       if(payload.redirect_url){
        window.location.href = payload.redirect_url
       }
       

      } catch (e) {
        console.log("error :", e);
      }
    })();
  }, [checkoutViaPaytab, sessionId]);

  return (
    <>
    <Loader/>
  </>
  )
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
     
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getSessionInfo: fawryCheckoutActions.getSessionInfo,
  checkout3D: fawryCheckoutActions.checkoutVia3D,
  checkoutViaQr: fawryCheckoutActions.checkoutViaQr,
  checkoutViaR2p: fawryCheckoutActions.checkoutViaR2p,
  checkoutViaFawryRef: fawryCheckoutActions.checkoutViaFawryRef,
  checkStatus: fawryCheckoutActions.checkStatus,
  updateStatus: fawryCheckoutActions.updateStatus,
  getService: servicesActions.getServiceBySid,
  checkoutViaPaytab: fawryCheckoutActions.checkoutViaPaytab
  
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const PaytabCheckoutComponentRedux = connector(PaytabCheckoutComponent);

export { PaytabCheckoutComponentRedux as PaytabCheckoutComponent };
