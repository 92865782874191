import React, { useEffect , useState} from "react";
import { connect, ConnectedProps } from "react-redux";
import qs from 'query-string';
import Box from '@mui/material/Box';
import { RootState } from "&store/store";
import { fawryCheckoutActions } from "../egyptCheckout.slice";
import {servicesActions} from '&features/services/services.slice';
import Loader from "&styled/loader/loader";
import { hmacCalculation } from "&config/hmacCalculation";

type ReduxProps = ConnectedProps<typeof connector>;

const PaymobRedirectComponent = (props: ReduxProps) => {
  const [isError, setIsError] = useState("")
  const { queryParams, getService, updateStatus} = props;
  const parsed:any = qs.parse(queryParams);
  // Validate HMAC
  
  const sourceDataPan = parsed['source_data.pan'];
  const sourceDataSubType = parsed['source_data.sub_type'];
  const sourceDataType = parsed['source_data.type'];
  const message = parsed['data.message'];
  delete parsed['data.message'];
  delete parsed['source_data.pan'];
  delete parsed['source_data.sub_type'];
  delete parsed['source_data.type'];

  parsed.source_pan = sourceDataPan;
  parsed.source_data_subtype = sourceDataSubType;
  parsed.source_data_type = sourceDataType;
  parsed.message = message;

  const isValidatedHmac = hmacCalculation(parsed);

  const storedService = localStorage.getItem("service");
  let serviceId, orderId;
  if(storedService){
    serviceId = storedService.split("-")[0];
    orderId = storedService.split("-")[1];
    parsed.serviceId = serviceId;
    parsed.orderId = orderId;
  }
  parsed.paymentMethod = "MW";
  parsed.serviceId = serviceId;

  useEffect(() => {
(async()=>{
  
  await updateStatus(parsed)
  // get service by sid
  if(!isValidatedHmac){
    return setIsError("Failed to authenticate resource request")
  }
  const {payload} = await getService(serviceId)
  // update transaction
  // get the redirect url
  const clientUrl = payload.portalUrl;
  // modify the response and redirect to client
  let redirectStr = "";
  if(parsed.success === "true"){
    redirectStr = `?status=SUCCESS&orderId=${orderId}&amount=${parsed.amount_cents}`
  }else{
    redirectStr = `?status=FAILED&orderId=${orderId}`
  }
if(clientUrl){
   window.location.href = clientUrl + redirectStr;
}else{
    return setIsError("Merchant's welcome page not configured")
}

})()
  }, [queryParams, serviceId, getService, parsed, updateStatus, orderId, isValidatedHmac]);

  return (
    <Box sx={{width:"100vw", height: "100vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
    {!isError && <Loader/>}
    {isError && <Box>{isError}</Box>}
  </Box>
  )
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState, ownProps) => ({
  queryParams: ownProps.location.search
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getService: servicesActions.getServiceBySid,
  updateStatus: fawryCheckoutActions.updatePaymobStatus
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const PaymobRedirectComponentRedux = connector(PaymobRedirectComponent);

export { PaymobRedirectComponentRedux as PaymobRedirectComponent };
